import REQ from "@/app/REQ";
import {mapGetters} from "vuex";

export default {
    props: ['id'],

    data() {
        return {
            refLaw: {},
            preface: [""],//导言
            chapters: [
                {orderN: '', title: '', items: [{orderN: '', content: ['']}]}//章节序号orderN 章节名title 章节段落items 条款序号orderN 条款段落content
            ],//章节
            editing: false,
            linkUrl: '',
        }
    },

    computed: {
        ...mapGetters(['amIPlatAdm']),
    },

    created() {
        this.getRefLaws()
    },

    methods: {

        getRefLaws() {
            this.req2Data(REQ.law, {id: this.id}, 'refLaw', (refLaw) => {
                if (refLaw.linkUrl){
                    this.linkUrl = refLaw.linkUrl
                }
                if (refLaw.content) {
                    this.preface = refLaw.content.preface
                    this.chapters = refLaw.content.chapters
                    return
                }
                if(this.amIPlatAdm) this.editing = true
            })
        },

        editLaw(){
            let {id, preface, chapters, linkUrl} = this
            preface = preface.map(it => it.replace())
            chapters.forEach(it => {
                it.orderN = it.orderN.replace()
                it.title = it.title.replace()
                it.items.forEach(item =>{
                    item.orderN = item.orderN.replace()
                    item.content = item.content.map(itm => itm.replace())
                })
            })
            this.req2Data(REQ.upLawCotent, {id, preface, chapters, linkUrl}, {}, () => {
                this.getRefLaws()
                this.editing = false
            })
        },

        addLaw() {
            this.editText('', '添加法规', law => {
                let {preface, chapters} = this
                preface = ['']
                chapters = [{items: [{content: ['']}]}]
                let strs = law.replace('\r', '').split('\n').map(it => it.trim()).filter(it => it.length > 0)
                let prefaceIdx = 0
                let chapterIdx = -1
                let clauseIdx = -1
                let contentIdx = 0

                let result
                let chapterRegEx = new RegExp("^第[0-9零一二三四五六七八九十百千]{1,5}章")
                let clauseRegEx = new RegExp("^第[0-9零一二三四五六七八九十百千]{1,10}条")
                strs.forEach(it => {
                    if ((result = it.match(chapterRegEx)) != null) {
                        clauseIdx = -1
                        chapterIdx++
                        if (!chapters[chapterIdx]) chapters.push({items: [{content: []}]})
                        chapters[chapterIdx].orderN = it.substring(0, result[0].length)
                        chapters[chapterIdx].title = it.substr(result[0].length)
                    } else if ((result = it.match(clauseRegEx)) != null) {
                        contentIdx = 0
                        clauseIdx++
                        if (!chapters[chapterIdx].items[clauseIdx]) chapters[chapterIdx].items.push({content: []})
                        chapters[chapterIdx].items[clauseIdx].orderN = it.substring(0, result[0].length)
                        if(it.substr(result[0].length)) {
                            chapters[chapterIdx].items[clauseIdx].content[contentIdx] = it.substr(result[0].length)
                            contentIdx++
                        }
                    } else if (chapterIdx == -1) {
                        preface[prefaceIdx] = it
                    } else {
                        if (!chapters[chapterIdx].items[clauseIdx]) chapters[chapterIdx].items.push({content: []})
                        chapters[chapterIdx].items[clauseIdx].content[contentIdx] = it
                        contentIdx++
                    }
                })
                this.preface = preface
                this.chapters = chapters
            })
        },

        insert(arr, idx, obj, sign, idex) {
            arr.splice(idx, 0, obj)
            if (sign == 1 && idx + 1 == this.chapters.length) {
                this.chapters[idx].orderN = '第' + this.convertToChinaNum(idx + 1) + '章'
                this.chapters[idx].items[0].orderN = '第' + this.convertToChinaNum(1) + '条'
            } else if (sign == 2 && idx + 1 == this.chapters[idex].items.length) {
                this.chapters[idex].items[idx].orderN = '第' + this.convertToChinaNum(idx + 1) + '条'
            }
        },

        remove(arr, idx) {
            arr.splice(idx, 1)
        }
    }
};
